<template>
  <div class="content-tree text-xs overflow-y-scroll pb-4">
    <div class="p-2 mt-2 text-gray-100">
      Extras<br>
      Mostly debug + testing features
    </div>
  </div>
</template>
<script>

export default {
  name: 'ExtrasTree'
}
</script>
